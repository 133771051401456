.header {
    background-color: #4a4a4a;
    padding: 2rem;
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.07); /* Adiciona uma leve sombra */

}

.site-title {
    color: white;
    font-size: 2.3rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 2px;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.097);
    background: linear-gradient(45deg, #f4f3f4, #ffffff);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: gradientMove 5s infinite alternate;
  }
  
  @keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
  
