* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f0f0f0;
    color: #333;
}

.header {
    background-color: #006a88;
    color: white;
    text-align: center;
    padding: 2.4rem;
    box-shadow: 0 8px 9px rgba(0, 0, 0, 0.238); /* Adiciona uma leve sombra */
}


.footer {
    background-color: #c86673;
    color: white;
    text-align: center;
    padding: 2rem;
}

.footer {
    margin-top: auto;
    box-shadow: 8px 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra */

}

.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #fff;
}


.labelText {

    font-size:14;
    
    font-weight:'bold'
    }

.form-group {
    width: 100%;
    max-width: 300px;
    margin-bottom: 1.5rem;

}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #bbbaba;
}

.input-numeral, .select-estados, .btn-prosseguir {
    width: 100%;
    padding: 0.6rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.input-numeral, .select-estados {
    background-color: #f9f9f9;
}

.select-estados2 {
    width: 100%;
    padding: 0.6rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #f9f9f9;
}

.btn-prosseguir {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-prosseguir.enabled {
    background-color: #87898d;
    color: white;
}

.btn-prosseguir.enabled:hover {
    background-color: #a2a5a6;
}

.btn-prosseguir.disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
}

@media (max-width: 600px) {
    .header, .footer {
        padding: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona uma leve sombra */

    }

    .main-content {
        padding: 1rem;
    }

    .logo {
        width: 80px;
    }

    .form-group {
        max-width: 250px;
    }
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    width: 800px;
    max-height: 90%;
    overflow-y: auto;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

@media (max-width: 768px) {
    .Modal {
        width: 90%;
        max-width: none;
    }
}

@media (max-width: 480px) {
    .Modal {
        width: 95%;
        padding: 15px;
    }
}

.success-alert {
    background-color: #e8e8e8; /* Fundo verde claro */
    border: 2px solid #c1c3c1; /* Borda verde */
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
}
.erro-alert {
    background-color: #f43210; /* Fundo verde claro */
    border: 2px solid #b42d1e; /* Borda verde */
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
}
.erro-alert .main-text {
    color: #f5f5f5; /* Verde para o título */
    margin-bottom: 10px;
}

.success-alert .main-text {
    color: #06ca0d; /* Verde para o título */
    margin-bottom: 10px;
    font-weight: bold;
}

.success-alert .left-align-text {
    text-align: justify; /* Justificar texto */
}

.success-alert h4 {
    color: #333; /* Cinza escuro para o texto */
    margin-bottom: 5px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.btn-prosseguir {
    background-color: #4caf50; /* Verde para o botão */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-prosseguir.enabled:hover {
    background-color: #7c787a; /* Verde escuro ao passar o mouse */
}

.btn-prosseguir.disabled {
    background-color: #ccc; /* Cinza claro para o botão desabilitado */
    cursor: not-allowed;
}

.captcha-container {
    display: flex;
    align-items: center;
}

.captcha {
    font-weight: bold;
    font-size: 1.6em;
    margin-right: 6px;
    background-color: #f0f0f0;
    padding: 10px 10px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.btn-refresh-captcha {
    margin-left: 10px;
    cursor: pointer;
}

.input-captcha {
    margin-top: 10px;
    padding: 5px;
    width: 100%;
    margin-top: 8px;
}

.error {
    color: red;
    font-size: 0.9em;
    margin-top: 5px;
}